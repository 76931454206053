import React from "react"
import { string, shape, node, object } from "prop-types"
import skin from "../_globalSettings/skin"
import Button from "../Link/Button"
import StyledBecomeMember from "./style"
import key from "../../utils/key"
import { Heading3 } from "../_globalSettings/typography"

const BecomeMember = ({ headline, list, priceText, button }) => {
	return (
		<StyledBecomeMember>
			<div className="member__blue-bg" />
			<div className="member__text">
				<Heading3>{headline}</Heading3>
				<ul className="member__list">
					{list.map((item, i) => (
						<li className="member__list-item" key={key(i, item)}>
							{item}
						</li>
					))}
				</ul>
			</div>
			<div className="member__CTA">
				<span className="member__price">{priceText}</span>
				<Button to="/clenstvi" skin={skin.blueInverseDark}>
					{button.label}
				</Button>
			</div>
		</StyledBecomeMember>
	)
}

export const becomeMemberProps = {
	modalHeadline: string,
	formData: object.isRequired, // eslint-disable-line
	headline: string,
	content: string,
	image: shape({
		publicURL: string,
	}),
	link: shape({
		url: string,
		label: string,
	}),
	children: node,
}

BecomeMember.propTypes = {
	...becomeMemberProps,
}

BecomeMember.defaultProps = {
	link: undefined,
	image: undefined,
	headline: undefined,
	content: undefined,
	children: undefined,
}

export default BecomeMember
