import React from "react"
import styled from "styled-components"
import { shape } from "prop-types"
import Layout, { layoutProps } from "../components/Layout"
import Row from "../components/Layout/Content/Row"
import Card from "../components/Card"
import BecomeMember from "../components/BecomeMember"
import Partners from "../components/Partners"
import A from "../components/Link"
import { Heading1 } from "../components/_globalSettings/typography"
import { breakpoint } from "../components/_globalSettings/var"

const MembersWrap = styled.div`
	display: flex;
	padding-top: 93px;
	padding-bottom: 111px;
	.members__left {
		padding-right: 5.8%;
		width: 65%;
		flex-shrink: 0;
	}
	@media (max-width: ${breakpoint.default.to}px) {
		flex-direction: column;
		.members__left {
			padding-right: 0;
			width: 100%;
		}
		padding-bottom: 0;
	}

	@media (max-width: ${breakpoint.preMin.to}px) {
		padding-top: 40px;
		h1 {
			margin-bottom: 117px;
		}
		.members__left {
			margin-bottom: 13px;
		}
	}
`

const Members = ({ pageContext }) => {
	return (
		<Layout {...pageContext}>
			<Row wrap="wrap" color="#FFF">
				<MembersWrap>
					<div className="members__left">
						<Heading1>Členové ASWA</Heading1>
						<div className="members__boxes">
							{pageContext.members.nodes.map(member => {
								return (
									<Card
										{...member}
										key={member.id}
										id={member.selfLink}
										type="member"
										className="members"
										linkArea={
											<A href={member.link.url} className="card__link">
												{member.link.label}
											</A>
										}
									/>
								)
							})}
						</div>
					</div>
					<BecomeMember
						{...pageContext.becomeMember}
						modalHeadline={pageContext.logInForm.headline}
						formData={pageContext.logInForm.form}
					/>
				</MembersWrap>
			</Row>
			<Row wrap="wrap" color="#F7F8FB">
				<Partners {...pageContext.partners} title="Naši partneři" />
			</Row>
		</Layout>
	)
}

Members.propTypes = {
	pageContext: shape({
		...layoutProps,
	}).isRequired,
}

export default Members
