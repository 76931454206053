import styled from "styled-components"
import { breakpoint } from "../_globalSettings/var"
import { color } from "../_globalSettings/skin"
import { typography } from "../_globalSettings/typography"

const StyledBecomeMember = styled.div`
	display: flex;
	background: ${color.main.primary1};
	align-self: start;
	flex-direction: column;
	padding: 64px;
	position: sticky;
	top: 80px;
	h3 {
		color: #fff;
		margin-bottom: 47px;
	}
	.member__image {
		width: 100%;
		max-width: 300px;
		padding: 30px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: auto;
		padding: 30px;
	}
	.member__price {
		padding-left: 46px;
		position: relative;
		font-weight: 600;
		font-size: 26px;
		line-height: 130%;
		color: #fff;
		&:before {
			content: "";
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			background-image: url("/css/tag.svg");
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.member__CTA {
		display: inline-block;
		text-align: center;
		margin-top: 47px;
		line-height: 27px;
		button,
		a {
			width: 100%;
			margin-top: 16px;
			height: 62px;
			color: ${color.main.primary1};
		}
	}
	.member__text {
		flex-grow: 1;
	}
	.member__list {
		padding-left: 23px;
		color: #f3f5fa;
		${typography.BodyL};
		padding-bottom: 35px;
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
	}
	.member__list-item {
		padding-bottom: 12px;
	}

	@media (max-width: ${breakpoint.preMax.to}px) {
		padding: 32px;
	}

	@media (max-width: ${breakpoint.default.to}px) {
		width: 100%;
	}

	@media (max-width: ${breakpoint.preMin.to}px) {
		flex-direction: column;
		position: relative;
		padding: 100px 0;
		h3 {
			font-size: 33px;
			line-height: 130%;
			padding-bottom: 0;
		}
		.member__image {
			display: flex;
		}
		.member__blue-bg {
			position: absolute;
			width: calc(100% + 32px);
			height: 100%;
			left: -16px;
			top: 0;
			background: ${color.main.primary1};
		}
		.member__CTA,
		.member__text {
			position: relative;
			z-index: 1;
		}
	}
`

export default StyledBecomeMember
